.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.desativado {
  background-color: rgba(255, 201, 191, 0.685);
}

.link {
  display: block;
}

.modal-body2 {
  width: 750px !important;
  overflow-x: hidden !important;
}

.modal-content {
  width: 760px !important;
  overflow-x: hidden !important;
}
