.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.icons2 {
  font-size: 18px;
  display: inline;
  cursor: pointer;
  margin-left: 5px;
}

.candidato {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}
