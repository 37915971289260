@media (max-width: 768px) {
  #about img {
    margin: 0px 0;
  }
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }

  #about {
    padding: 36px 0px;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #intro,
  #portfolio,
  #footer {
    width: 100%;
  }

  header .intro-text {
    padding-top: 93px;
    padding-bottom: 100px;
    width: 360px;
  }

  .intro h1 {
    font-size: 52px;
    text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
  }

  .intro p {
    text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.44);
  }

  #root {
    overflow: hidden;
  }
  .texto-footer {
    display: block;
  }
  .about-text {
    margin-top: 30px;
  }
  #features .quadradoCol {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  #features .caixaAzul {
    flex-direction: column;
  }

  #features .quadradoRow {
    width: 100%;
    text-align: center;
    height: auto;
  }

  #features .quadradoRowIcon {
    display: none;
  }
  .denuncia {
    padding-top: 0px;
  }
  .fontes {
    font-size: "18px";
  }

  #root .pop2 {
    width: 368px;
    left: 50%;
    margin-left: -184px;
    margin-top: 1398px;
    padding: 37px;
  }

  #root .pop2 .pdd {
    width: 305px;
    height: 890px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #root .pop {
    width: 367px;
    height: 800px;
    margin-left: -183px;
    margin-top: 158px;
    padding: 40px;
  }

  #root .tabela {
    width: 296px;
    height: 729px;
    overflow-y: scroll;
  }
  #root .tabela .table {
    margin-top: 20px;
  }
}

.demuncia {
  font-size: 20px;
  font-family: sans-serif;
  color: rgb(255, 255, 255);
  padding-bottom: 10px;
  padding-top: 90px;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 0;
  font-size: 18px;
  line-height: 20px;
}

#menu.navbar-default .navbar-nav > li > button {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
  border: none;
  background: white;
}

#menu.navbar-default .navbar-nav > li > button:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > button:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > button,
.navbar-default .navbar-nav > .active > button:hover,
.navbar-default .navbar-nav > .active > button:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > button:after,
.navbar-default .navbar-nav > .active > button:hover:after,
.navbar-default .navbar-nav > .active > button:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 203px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.dropdown-item {
  padding: 8px;
  display: block;
  color: #0b336b;
}

.dropdown-menu {
  text-align: center;
}

.intrasmall {
  font-family: "Raleway", sans-serif;
  color: #fff !important;
  font-size: 70px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  line-height: 65px;
}

.introducao_sub {
  font-family: "Raleway", sans-serif;
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: right;
}
.introLargura {
  width: 100%;
  height: 500px;
  background-image: linear-gradient(
      260deg,
      #0b336b 20%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(./components/vagasDisponiveis/css/banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.menuVagas {
  width: 100%;
  height: 70px;
  background-color: #fff;
  filter: drop-shadow(-1px 2px 5px rgba(0, 0, 0, 0.57));
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.menuVagas ul {
  margin-top: 23px;
  margin-left: 40px;
}

.logoVagas {
  width: 80px;
  margin-left: 20px;
  margin-top: 10px;
}

.b01 {
  width: 600px;
  height: 300px;
  float: right;
  margin-top: 120px;
  margin-right: 100px;
}

@media (max-width: 768px) {
  .introLargura {
    width: 370px;
  }
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.btvaga {
  margin-top: 10px;
  margin-bottom: 20px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}
.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

.select {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #777;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.caixaAzul {
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%) !important;
  border-radius: 20px;
  padding: 20px;
  color: white;
  flex-direction: row;
  display: flex;
  text-align: left;
  gap: 23px;
}
.subtitulo {
  font-size: 22px;
  font-weight: 600;
}

.quadradoCol {
  width: 33%;
  height: 200px;
  padding: 20px;
}

.quadradoRow {
  width: 33%;
  height: 200px;
  padding: 20px;
  flex-direction: row;
  display: flex;
}

.yellow {
  color: #f0c504;
  margin-top: 30px;
  margin-right: 8px;
}

.btn-outline {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
}

.btn-custom2 {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: solid 2px;
  background-color: none;
  cursor: pointer;
}

.btn-custom2:hover {
  color: rgb(243, 243, 243);
}

.pop {
  width: 800px;
  height: 400px;
  background-color: #fff;
  border-radius: 18px;
  position: absolute;
  -webkit-box-shadow: 0px 3px 16px 0px rgba(56, 56, 56, 0.62);
  box-shadow: 0px 3px 16px 0px rgba(56, 56, 56, 0.62);
  z-index: 9;
  left: 50%;
  margin-left: -400px;
  margin-top: 20px;
  padding: 40px;
}

.tabela {
  width: 726px;
  height: 336px;
  overflow-y: scroll;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.cabecalho {
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%) !important;
  color: #fff;
  font-weight: 400;
}

.pop2 {
  width: 800px;
  height: auto;
  background-color: #fff;
  border-radius: 18px;
  position: absolute;
  -webkit-box-shadow: 0px 3px 16px 0px rgba(56, 56, 56, 0.62);
  box-shadow: 0px 3px 16px 0px rgba(56, 56, 56, 0.62);
  z-index: 9;
  left: 50%;
  margin-left: -400px;
  margin-top: 80px;
  padding: 40px;
}

.pdd {
  width: 740px;
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.table-contato {
  color: rgba(255, 255, 255, 0.75);
}

.table-contato td {
  vertical-align: middle;
}

.thead {
  background: #fff;
  color: #0b336b;
  font-weight: 400;
}

.barraAzul {
  background: #12386e;
  padding: 10px 15px 15px 15px;
  width: 99.5%;
  height: 55px;
  z-index: 99;
  position: absolute;
  bottom: 0;
  text-align: center;
  margin-left: -14px;
}
.titulo_ {
  color: #fff;
  font-size: 10px;
}

.subtitulo_ {
  color: #fff;
  font-size: 14px;
}
