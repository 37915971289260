@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  #about img {
    margin: 0px 0;
  }
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }

  #about {
    padding: 36px 0px;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #intro,
  #portfolio,
  #footer {
    width: 100%;
  }

  header .intro-text {
    padding-top: 93px;
    padding-bottom: 100px;
    width: 360px;
  }

  .intro h1 {
    font-size: 52px;
    text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.2);
  }

  .intro p {
    text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.44);
  }

  #root {
    overflow: hidden;
  }
  .texto-footer {
    display: block;
  }
  .about-text {
    margin-top: 30px;
  }
  #features .quadradoCol {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  #features .caixaAzul {
    flex-direction: column;
  }

  #features .quadradoRow {
    width: 100%;
    text-align: center;
    height: auto;
  }

  #features .quadradoRowIcon {
    display: none;
  }
  .denuncia {
    padding-top: 0px;
  }
  .fontes {
    font-size: "18px";
  }

  #root .pop2 {
    width: 368px;
    left: 50%;
    margin-left: -184px;
    margin-top: 1398px;
    padding: 37px;
  }

  #root .pop2 .pdd {
    width: 305px;
    height: 890px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #root .pop {
    width: 367px;
    height: 800px;
    margin-left: -183px;
    margin-top: 158px;
    padding: 40px;
  }

  #root .tabela {
    width: 296px;
    height: 729px;
    overflow-y: scroll;
  }
  #root .tabela .table {
    margin-top: 20px;
  }
}

.demuncia {
  font-size: 20px;
  font-family: sans-serif;
  color: rgb(255, 255, 255);
  padding-bottom: 10px;
  padding-top: 90px;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 0;
  font-size: 18px;
  line-height: 20px;
}

#menu.navbar-default .navbar-nav > li > button {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
  border: none;
  background: white;
}

#menu.navbar-default .navbar-nav > li > button:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > button:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > button,
.navbar-default .navbar-nav > .active > button:hover,
.navbar-default .navbar-nav > .active > button:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > button:after,
.navbar-default .navbar-nav > .active > button:hover:after,
.navbar-default .navbar-nav > .active > button:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 203px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.dropdown-item {
  padding: 8px;
  display: block;
  color: #0b336b;
}

.dropdown-menu {
  text-align: center;
}

.intrasmall {
  font-family: "Raleway", sans-serif;
  color: #fff !important;
  font-size: 70px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  line-height: 65px;
}

.introducao_sub {
  font-family: "Raleway", sans-serif;
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: right;
}
.introLargura {
  width: 100%;
  height: 500px;
  background-image: linear-gradient(
      260deg,
      #0b336b 20%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(/static/media/banner.ef6cc4b2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.menuVagas {
  width: 100%;
  height: 70px;
  background-color: #fff;
  filter: drop-shadow(-1px 2px 5px rgba(0, 0, 0, 0.57));
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.menuVagas ul {
  margin-top: 23px;
  margin-left: 40px;
}

.logoVagas {
  width: 80px;
  margin-left: 20px;
  margin-top: 10px;
}

.b01 {
  width: 600px;
  height: 300px;
  float: right;
  margin-top: 120px;
  margin-right: 100px;
}

@media (max-width: 768px) {
  .introLargura {
    width: 370px;
  }
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.btvaga {
  margin-top: 10px;
  margin-bottom: 20px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}
.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

.select {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #777;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.caixaAzul {
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%) !important;
  border-radius: 20px;
  padding: 20px;
  color: white;
  flex-direction: row;
  display: flex;
  text-align: left;
  grid-gap: 23px;
  gap: 23px;
}
.subtitulo {
  font-size: 22px;
  font-weight: 600;
}

.quadradoCol {
  width: 33%;
  height: 200px;
  padding: 20px;
}

.quadradoRow {
  width: 33%;
  height: 200px;
  padding: 20px;
  flex-direction: row;
  display: flex;
}

.yellow {
  color: #f0c504;
  margin-top: 30px;
  margin-right: 8px;
}

.btn-outline {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
}

.btn-custom2 {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: solid 2px;
  background-color: none;
  cursor: pointer;
}

.btn-custom2:hover {
  color: rgb(243, 243, 243);
}

.pop {
  width: 800px;
  height: 400px;
  background-color: #fff;
  border-radius: 18px;
  position: absolute;
  box-shadow: 0px 3px 16px 0px rgba(56, 56, 56, 0.62);
  z-index: 9;
  left: 50%;
  margin-left: -400px;
  margin-top: 20px;
  padding: 40px;
}

.tabela {
  width: 726px;
  height: 336px;
  overflow-y: scroll;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.cabecalho {
  background: linear-gradient(to right, #0b336b 0%, #5ca9fb 100%) !important;
  color: #fff;
  font-weight: 400;
}

.pop2 {
  width: 800px;
  height: auto;
  background-color: #fff;
  border-radius: 18px;
  position: absolute;
  box-shadow: 0px 3px 16px 0px rgba(56, 56, 56, 0.62);
  z-index: 9;
  left: 50%;
  margin-left: -400px;
  margin-top: 80px;
  padding: 40px;
}

.pdd {
  width: 740px;
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.table-contato {
  color: rgba(255, 255, 255, 0.75);
}

.table-contato td {
  vertical-align: middle;
}

.thead {
  background: #fff;
  color: #0b336b;
  font-weight: 400;
}

.barraAzul {
  background: #12386e;
  padding: 10px 15px 15px 15px;
  width: 99.5%;
  height: 55px;
  z-index: 99;
  position: absolute;
  bottom: 0;
  text-align: center;
  margin-left: -14px;
}
.titulo_ {
  color: #fff;
  font-size: 10px;
}

.subtitulo_ {
  color: #fff;
  font-size: 14px;
}

/*
 * Specific styles of signin component
 */
/*
 * General styles
 */
body,
html {
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(#1a2058, #427bb7);
}

.fill {
  background-image: linear-gradient(90deg, #1a2058, #427bb7);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: scroll;
}

.checkbox {
  margin-bottom: 40px;
}

.card-container.card {
  max-width: 350px;
  padding: 40px 40px;
}

.btnl {
  font-weight: 700;
  height: 36px;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

/*
 * Card component
 */
.card {
  background-color: #f7f7f7;
  /* just in case there no content*/
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.modal-altura {
  height: auto;
  overflow-y: auto;
  padding: 40px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 0;
  min-height: 1em;
}

.reauth-email {
  display: block;
  color: #404040;
  line-height: 2;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
  direction: ltr;
  height: 44px;
  font-size: 16px;
}

.form-signin input[type="email"],
.form-signin input[type="password"],
.form-signin input[type="text"],
.form-signin button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  border-color: rgb(104, 145, 162);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(104, 145, 162);
}

.btnl.btn-signin {
  /*background-color: #4d90fe; */
  background-color: #337ab7;
  /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
  padding: 0px;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  border-radius: 3px;
  border: none;
  transition: all 0.218s;
  border-color: #2e6da4;
}

.btnl.btn-signin:hover,
.btnl.btn-signin:active,
.btnl.btn-signin:focus {
  background-color: rgb(214, 214, 214);
}

button.close {
  position: absolute;
  right: 10px;
  top: 16px;
}

.forgot-password {
  color: #337ab7;
  background: none;
  border: none;
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus {
  color: rgb(37, 91, 161);
}

.modal {
  display: block;
}

.main {
  background: #e0e8f2;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 100vh;
}
.menu {
  text-align: left;
  padding-top: 30px;
  padding-left: 20px;
}
.menuitens {
  cursor: pointer;
}

.menu ul li {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #bbbbbb6e;
  width: 100%;
}

.menu ul li ul li:hover {
  background-color: #bbbbbb6e;
}

.menu ul li ul li {
  font-size: 13px;
  border-bottom: 0px;
  margin-left: 15px;
  background-color: #dee7f1;
  color: #337ab7;
  margin-bottom: 5px;
  margin-top: 5px;
}

.btd {
  background: none;
  border: none;
}

.btdp {
  background-color: #337ab7;
  color: #fff;
  float: right;
  margin: 20px 0px 20px 20px;
}

.btdp:hover {
  color: #fff;
}

.dropdown {
  float: right;
}

.cards {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 13px #bbbbbb6e;
  padding: 20px;
  margin: 30px 20px;
}

.btsair {
  float: right;
  margin: 15px;
}

.seta {
  float: right;
  font-size: 12px;
  margin-top: 4px;
}

.seta-down {
  transform: rotate(90deg);
}
.nested {
  display: none;
}
.active {
  display: block;
}

.titleDash {
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
  margin-left: 5px;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.green {
  color: rgb(147, 243, 176);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.modal-body2 {
  /* height: 80vh; */
  overflow-y: auto;
  padding: 40px;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.icons2 {
  font-size: 18px;
  display: inline;
  cursor: pointer;
  margin-left: 5px;
}

.candidato {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.desativado {
  background-color: rgba(255, 201, 191, 0.685);
}

.link {
  display: block;
}

.modal-body2 {
  width: 750px !important;
  overflow-x: hidden !important;
}

.modal-content {
  width: 760px !important;
  overflow-x: hidden !important;
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

.col-3 {
  width: 25%;
}

.col-1 {
  width: 5%;
}

.red {
  color: rgb(243, 161, 147);
}

.blue {
  color: rgb(35, 123, 224);
}

.icons {
  font-size: 24px;
  display: block;
  cursor: pointer;
}

.list-group {
  margin: 4rem auto;
  padding: 0;
  width: 880px;
}

#card-vaga{
  white-space: pre-wrap;
}

#avisoManutencao {
  border: #ddd dotted 2px;
  width: 80vw;
  margin-left: 10vw; margin-right: 5vw;
  margin-bottom: 2vh;
  text-align: center;
}
#avisoManutencao h3{
  color: white;
}

@media (max-width: 768px) {
  .list-group {
    width: 357px;
    text-align: center;
  }

  .botao {
    margin-top: 20px;
    margin-right: "20px";
    text-align: center;
  }
}

.botao {
  margin-top: 20px;
  float: "right";
  margin-right: "20px";
}

.list-group-item {
  position: relative;
  display: block;
  padding: 18px 34px 34px 34px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

hr {
  border-bottom: solid 1px #2d65cc;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}
.titulo {
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
}
.divisor {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.modal {
  display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  height: 80vh;
  overflow-y: auto;
  padding: 40px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 5px 8px 5px 8px;
  font-size: 12px;
  font-weight: 400;
}
.atencao {
  color: #cc2d2d;
}

.estilo {
  border: solid 1px #aeaeae;
  border-radius: 5px;
  padding: 28px;
  background-color: #f5eae2;
}

@media (max-width: 768px) {
  .menuVagasDisponiveis {
    display: none;
  }
}

.menuVagasDisponiveis li {
  display: inline;
  color: #7c7c7c;
  font-size: 15px;
  margin-left: 30px;
  margin-top: 30px;
  height: 20px;
}

.txtvagas {
  text-align: center;
  font-size: 38px;
  color: #fff;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 40px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.inputBusca {
  width: 880px;
  margin: 4rem auto;
  flex-direction: row;
  display: flex;
}

a{
  color: #2d65cc;
}


@media (max-width: 600px) {
  #cadastro-container{
    width: 80vw;
  }
  .texto{
    text-align: center;
    padding-top: 20px;
  }
  .radiob{
    margin-top: 10px!important;
  }
}



/* Configurações gerais */

#titulo-geral-campanhas{
    font-family: 'Nunito Sans', sans-serif;
    color: #fff;
}

.intro-campanhas{
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#slogan-campanhas{
    color: #1a2058;
    text-shadow: 1px 1px #fff;
}

#intro-geral-campanhas{
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#pag-campanhas{
    background-image: linear-gradient(#4178b4, #1a2058);
    padding-bottom: 1px;
}

#tituloListagem-campanhas{
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    text-decoration: underline;
    text-transform: none;
}

#lista-campanhas{
    list-style-type: disc;
    border: 1px solid #ddd;
    width: -moz-fit-content;
    width: fit-content;
    list-style-position: inside;
    border-bottom: none;
}

#lista-campanhas li{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 5px;
}

.titulo-secao{
    font-family: 'Nunito Sans', sans-serif;
    color: #fff;
}

.link-verde{
    color: #baf235;
}

.foto-sms{
    width: 90%;
    margin-left: 5%; margin-right: 5%;
    /*opacity: 0;*/

    animation: fade-in linear;
    animation-timeline: view(55vh -2vh);
    /* animation-range-start: 3vh;
    animation-range-end: -10vh; */
}
@keyframes fade-in{
    from { scale: .8; opacity: 0; }
    to { scale: 1; opacity: 1; }
}


  /* celular */
@media (max-width: 500px) {
    .ocultar-mob{
        display: none;
    }

    #menu-campanhas{
        margin-bottom: -3vh !important;
    }

    #introducao{
        background-image: url(/static/media/campanhas-bg-translucido.ad82a501.png);
        background-repeat: no-repeat;
        background-size:cover;
    }

    #con-slogan{
        padding: 1vh 0 1vh 1vh;
    }
    
    #con-titulo{
        width: 100%;
        padding-top: 2%;
        text-align: center;
    } 

    .titulo-secao{
        font-size: 1.8em;
        margin-top: 1vh;
    }
    
    /* #saude, #meio-ambiente, #seguranca{
        padding-left: 2%;
        padding-right: 2%;
    }
    #saude{
        padding-top: 2%;
    }
    #seguranca{
        padding-bottom: 2%;
    } */
    section{
        padding: 2% 2% 2% 2%;
    }

    .titulo-secao{
        margin-bottom: 1vh;
        margin-left: 5vw;
    }

    #conteudos-extra{
        width: 90%;
        margin: 0 5% 0 5%;
    }

    #lista-campanhas{
        width: 100%;
    }

    #tituloListagem-campanhas{
        font-size: 1.5em;
    }

    hr{
        width: 92%;
        margin-left: 4%; margin-right: 4%;
    }
}
  

  /* tablet */
@media (min-width: 501px) and (max-width: 1024px) {
    

    .ocultar-med{
        display: none;
    }

    #menu-campanhas{
        margin-bottom: -3vh !important;
    }

    #introducao{
        background-image: url(/static/media/campanhas-bg-translucido.ad82a501.png);
        background-repeat: no-repeat;
        background-size:cover;
    }
    #titulo-geral-campanhas{
        text-align: left;
        margin-left: 10%;
        font-size: 3.2em;
    }
    #slogan-campanhas{
        font-size: 2em;
    }
    #intro-geral-campanhas{
        font-size: 1.5em;
    }

    #con-slogan{
        padding: 1vh 0 1vh 1vh;
    }
    
    #con-titulo{
        width: 100%;
        padding-top: 2%;
        text-align: center;
    } 

    .titulo-secao{
        font-size: 2.2em;
        margin-top: 1vh;
    }
    .intro-campanhas{
        font-size: 1.2em;
    }
    
    section{
        padding: 2% 2% 2% 2%;
    }

    .titulo-secao{
        margin-bottom: 1vh;
        margin-left: 5vw;
    }

    #conteudos-extra{
        width: 90%;
        margin: 0 5% 0 5%;
    }

    #tituloListagem-campanhas{
        font-size: 1.8em;
    }
    #lista-campanhas{
        width: 100%;
        font-size: 1.2em;
    }


    hr{
        width: 92%;
        margin-left: 4%; margin-right: 4%;
    }
}
  

  /* monitores */
@media (min-width: 1025px) {
    .ocultar-dsk{
        display: none;
    }

    #introducao{
        background-image: url(/static/media/campanhas-bg.50354dab.png);
        background-repeat: no-repeat;
        background-size:cover;
        min-height: 50vh;
    }

    .intro-campanhas{
        font-size: large;
        margin: 0 5% 4vh 5%;
    }
    
    #con-titulo{
        width: 50%;
        align-self: start;
    }
    #con-slogan{
        width: 50%;
        align-self: start;
        margin-left: 5%;
    }
    #con-intro{
        font-size: 1.5em;
        align-self: start;
        width: 50%;
        margin-left: 5%;
    }

    #titulo-geral-campanhas{
        font-size: 3.5em;
        margin: 2vh 20% 4vh 20%;
        width: 60%;
        text-align: center;
        font-weight: 900;
    }

    #slogan-campanhas{
        font-size: 2em;
    }

    #intro-geral-campanhas{
        font-size: 1em;
    }

    #carouselContainer{
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 5vh;
    }
    .carrossel{
        margin: 0 20% 0 20%;
    }

    .slick-prev {
        top: 50%;
        margin-left: -5%;
    }
    .slick-next {
        top: 50%;
        margin-right: -5%;
    }
    .slick-dots{
        top: 95%;
    }

    #tituloListagem-campanhas{
        font-size: 1.8em;
        margin: 5vh 0 1vw 5%;
        max-width: 90%;
    }

    #lista-campanhas{
        margin: 0 0 2vw 5%;
        padding: 0;
        width: -moz-fit-content;
        width: fit-content;
        min-width: 30vw;
    }

    .titulo-secao{
        font-size: 2.8em;
        margin: 0 10% 1vh 10vh;
        font-weight: 100;
    }

    hr{
        width: 92%;
        margin: 0 4% 0 4%;
    }

    section{
        padding-top: 2%;
    }

    #con-video-embed{
        padding: 1%;
        --s: 50px;
        border: #fff solid 3px;
        -webkit-mask:
        conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
        0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box;
    }
    #video-embed{
        width:100%; 
        height:50vh;
    }

    #conteudos-extra{
        display: grid;
        grid-template-columns:40vw 50vw;
        margin-bottom: 2vh;
    }
}
/*
 * Specific styles of signin component
 */
/*
 * General styles
 */
body,
html {
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(#1a2058, #427bb7);
}

.fill {
  background-image: linear-gradient(90deg, #1a2058, #427bb7);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: scroll;
  flex-direction: column;
  display: flex;
}

.checkbox {
  margin-bottom: 40px;
}

.card-container.card {
  max-width: 350px;
  padding: 40px 40px;
}

.btnl {
  font-weight: 700;
  height: 36px;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

/*
 * Card component
 */
.card {
  background-color: #f7f7f7;
  /* just in case there no content*/
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.modal-altura {
  height: auto;
  overflow-y: auto;
  padding: 40px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 0;
  min-height: 1em;
}

.reauth-email {
  display: block;
  color: #404040;
  line-height: 2;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
  direction: ltr;
  height: 44px;
  font-size: 16px;
}

.form-signin input[type="email"],
.form-signin input[type="password"],
.form-signin input[type="text"],
.form-signin button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

.form-signin .form-control:focus {
  border-color: rgb(104, 145, 162);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(104, 145, 162);
}

.btnl.btn-signin {
  /*background-color: #4d90fe; */
  background-color: #337ab7;
  /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
  padding: 0px;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  border-radius: 3px;
  border: none;
  transition: all 0.218s;
  border-color: #2e6da4;
}

.btnl.btn-signin:hover,
.btnl.btn-signin:active,
.btnl.btn-signin:focus {
  background-color: rgb(214, 214, 214);
}

button.close {
  position: absolute;
  right: 10px;
  top: 16px;
}

.forgot-password {
  color: #337ab7;
  background: none;
  border: none;
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus {
  color: rgb(37, 91, 161);
}

.modal {
  display: block;
}

