.list-group {
  margin: 4rem auto;
  padding: 0;
  width: 880px;
}

#card-vaga{
  white-space: pre-wrap;
}

#avisoManutencao {
  border: #ddd dotted 2px;
  width: 80vw;
  margin-left: 10vw; margin-right: 5vw;
  margin-bottom: 2vh;
  text-align: center;
}
#avisoManutencao h3{
  color: white;
}

@media (max-width: 768px) {
  .list-group {
    width: 357px;
    text-align: center;
  }

  .botao {
    margin-top: 20px;
    margin-right: "20px";
    text-align: center;
  }
}

.botao {
  margin-top: 20px;
  float: "right";
  margin-right: "20px";
}

.list-group-item {
  position: relative;
  display: block;
  padding: 18px 34px 34px 34px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

hr {
  border-bottom: solid 1px #2d65cc;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}
.titulo {
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
}
.divisor {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.modal {
  display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  height: 80vh;
  overflow-y: auto;
  padding: 40px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 5px 8px 5px 8px;
  font-size: 12px;
  font-weight: 400;
}
.atencao {
  color: #cc2d2d;
}

.estilo {
  border: solid 1px #aeaeae;
  border-radius: 5px;
  padding: 28px;
  background-color: #f5eae2;
}

@media (max-width: 768px) {
  .menuVagasDisponiveis {
    display: none;
  }
}

.menuVagasDisponiveis li {
  display: inline;
  color: #7c7c7c;
  font-size: 15px;
  margin-left: 30px;
  margin-top: 30px;
  height: 20px;
}

.txtvagas {
  text-align: center;
  font-size: 38px;
  color: #fff;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 40px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.inputBusca {
  width: 880px;
  margin: 4rem auto;
  flex-direction: row;
  display: flex;
}

a{
  color: #2d65cc;
}


@media (max-width: 600px) {
  #cadastro-container{
    width: 80vw;
  }
  .texto{
    text-align: center;
    padding-top: 20px;
  }
  .radiob{
    margin-top: 10px!important;
  }
}


