@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,600&display=swap');
/* Configurações gerais */

#titulo-geral-campanhas{
    font-family: 'Nunito Sans', sans-serif;
    color: #fff;
}

.intro-campanhas{
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#slogan-campanhas{
    color: #1a2058;
    text-shadow: 1px 1px #fff;
}

#intro-geral-campanhas{
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#pag-campanhas{
    background-image: linear-gradient(#4178b4, #1a2058);
    padding-bottom: 1px;
}

#tituloListagem-campanhas{
    font-family: 'Nunito Sans', sans-serif;
    color: white;
    text-decoration: underline;
    text-transform: none;
}

#lista-campanhas{
    list-style-type: disc;
    border: 1px solid #ddd;
    width: fit-content;
    list-style-position: inside;
    border-bottom: none;
}

#lista-campanhas li{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 5px;
}

.titulo-secao{
    font-family: 'Nunito Sans', sans-serif;
    color: #fff;
}

.link-verde{
    color: #baf235;
}

.foto-sms{
    width: 90%;
    margin-left: 5%; margin-right: 5%;
    /*opacity: 0;*/

    animation: fade-in linear;
    animation-timeline: view(55vh -2vh);
    /* animation-range-start: 3vh;
    animation-range-end: -10vh; */
}
@keyframes fade-in{
    from { scale: .8; opacity: 0; }
    to { scale: 1; opacity: 1; }
}


  /* celular */
@media (max-width: 500px) {
    .ocultar-mob{
        display: none;
    }

    #menu-campanhas{
        margin-bottom: -3vh !important;
    }

    #introducao{
        background-image: url(../img/campanhas-bg-translucido.png);
        background-repeat: no-repeat;
        background-size:cover;
    }

    #con-slogan{
        padding: 1vh 0 1vh 1vh;
    }
    
    #con-titulo{
        width: 100%;
        padding-top: 2%;
        text-align: center;
    } 

    .titulo-secao{
        font-size: 1.8em;
        margin-top: 1vh;
    }
    
    /* #saude, #meio-ambiente, #seguranca{
        padding-left: 2%;
        padding-right: 2%;
    }
    #saude{
        padding-top: 2%;
    }
    #seguranca{
        padding-bottom: 2%;
    } */
    section{
        padding: 2% 2% 2% 2%;
    }

    .titulo-secao{
        margin-bottom: 1vh;
        margin-left: 5vw;
    }

    #conteudos-extra{
        width: 90%;
        margin: 0 5% 0 5%;
    }

    #lista-campanhas{
        width: 100%;
    }

    #tituloListagem-campanhas{
        font-size: 1.5em;
    }

    hr{
        width: 92%;
        margin-left: 4%; margin-right: 4%;
    }
}
  

  /* tablet */
@media (min-width: 501px) and (max-width: 1024px) {
    

    .ocultar-med{
        display: none;
    }

    #menu-campanhas{
        margin-bottom: -3vh !important;
    }

    #introducao{
        background-image: url(../img/campanhas-bg-translucido.png);
        background-repeat: no-repeat;
        background-size:cover;
    }
    #titulo-geral-campanhas{
        text-align: left;
        margin-left: 10%;
        font-size: 3.2em;
    }
    #slogan-campanhas{
        font-size: 2em;
    }
    #intro-geral-campanhas{
        font-size: 1.5em;
    }

    #con-slogan{
        padding: 1vh 0 1vh 1vh;
    }
    
    #con-titulo{
        width: 100%;
        padding-top: 2%;
        text-align: center;
    } 

    .titulo-secao{
        font-size: 2.2em;
        margin-top: 1vh;
    }
    .intro-campanhas{
        font-size: 1.2em;
    }
    
    section{
        padding: 2% 2% 2% 2%;
    }

    .titulo-secao{
        margin-bottom: 1vh;
        margin-left: 5vw;
    }

    #conteudos-extra{
        width: 90%;
        margin: 0 5% 0 5%;
    }

    #tituloListagem-campanhas{
        font-size: 1.8em;
    }
    #lista-campanhas{
        width: 100%;
        font-size: 1.2em;
    }


    hr{
        width: 92%;
        margin-left: 4%; margin-right: 4%;
    }
}
  

  /* monitores */
@media (min-width: 1025px) {
    .ocultar-dsk{
        display: none;
    }

    #introducao{
        background-image: url(../img/campanhas-bg.png);
        background-repeat: no-repeat;
        background-size:cover;
        min-height: 50vh;
    }

    .intro-campanhas{
        font-size: large;
        margin: 0 5% 4vh 5%;
    }
    
    #con-titulo{
        width: 50%;
        align-self: start;
    }
    #con-slogan{
        width: 50%;
        align-self: start;
        margin-left: 5%;
    }
    #con-intro{
        font-size: 1.5em;
        align-self: start;
        width: 50%;
        margin-left: 5%;
    }

    #titulo-geral-campanhas{
        font-size: 3.5em;
        margin: 2vh 20% 4vh 20%;
        width: 60%;
        text-align: center;
        font-weight: 900;
    }

    #slogan-campanhas{
        font-size: 2em;
    }

    #intro-geral-campanhas{
        font-size: 1em;
    }

    #carouselContainer{
        height: fit-content;
        margin-bottom: 5vh;
    }
    .carrossel{
        margin: 0 20% 0 20%;
    }

    .slick-prev {
        top: 50%;
        margin-left: -5%;
    }
    .slick-next {
        top: 50%;
        margin-right: -5%;
    }
    .slick-dots{
        top: 95%;
    }

    #tituloListagem-campanhas{
        font-size: 1.8em;
        margin: 5vh 0 1vw 5%;
        max-width: 90%;
    }

    #lista-campanhas{
        margin: 0 0 2vw 5%;
        padding: 0;
        width: fit-content;
        min-width: 30vw;
    }

    .titulo-secao{
        font-size: 2.8em;
        margin: 0 10% 1vh 10vh;
        font-weight: 100;
    }

    hr{
        width: 92%;
        margin: 0 4% 0 4%;
    }

    section{
        padding-top: 2%;
    }

    #con-video-embed{
        padding: 1%;
        --s: 50px;
        border: #fff solid 3px;
        -webkit-mask:
        conic-gradient(at var(--s) var(--s),#0000 75%,#000 0)
        0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box;
    }
    #video-embed{
        width:100%; 
        height:50vh;
    }

    #conteudos-extra{
        display: grid;
        grid-template-columns:40vw 50vw;
        margin-bottom: 2vh;
    }
}