.main {
  background: #e0e8f2;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 100vh;
}
.menu {
  text-align: left;
  padding-top: 30px;
  padding-left: 20px;
}
.menuitens {
  cursor: pointer;
}

.menu ul li {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #bbbbbb6e;
  width: 100%;
}

.menu ul li ul li:hover {
  background-color: #bbbbbb6e;
}

.menu ul li ul li {
  font-size: 13px;
  border-bottom: 0px;
  margin-left: 15px;
  background-color: #dee7f1;
  color: #337ab7;
  margin-bottom: 5px;
  margin-top: 5px;
}

.btd {
  background: none;
  border: none;
}

.btdp {
  background-color: #337ab7;
  color: #fff;
  float: right;
  margin: 20px 0px 20px 20px;
}

.btdp:hover {
  color: #fff;
}

.dropdown {
  float: right;
}

.cards {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 13px #bbbbbb6e;
  padding: 20px;
  margin: 30px 20px;
}

.btsair {
  float: right;
  margin: 15px;
}

.seta {
  float: right;
  font-size: 12px;
  margin-top: 4px;
}

.seta-down {
  transform: rotate(90deg);
}
.nested {
  display: none;
}
.active {
  display: block;
}

.titleDash {
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
  margin-left: 5px;
}
